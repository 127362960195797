import "./src/styles/global.css";

export const onInitialClientRender = () => {
  window.scrollTo(0, 0);

  // // Disable scrolling
  // document.body.style.overflow = "hidden";

  // // Re-enable scrolling after 3 seconds (3000 milliseconds)
  // setTimeout(() => {
  //   document.body.style.overflow = "";
  // }, 5000);
};
